import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import { saveAs } from 'file-saver';
import css from './ShareQRCode.module.css';
import { MdOutlineIosShare } from "react-icons/md";

const ShareQRCode = ({ url }) => {
  const [showQRCode, setShowQRCode] = useState(false);

  const handleDownload = () => {
    const canvas = document.getElementById('qrCode');
    canvas.toBlob((blob) => {
      saveAs(blob, 'qrcode.png');
    });
  };

  return (
    <div style={{width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
      <div style={{width: '100%', display: 'flex', justifyContent: 'right'}}>
          <button
            onClick={() => setShowQRCode(!showQRCode)}
            className={css.toggleButton}
          >
            <div style={{display: 'grid', placeContent: 'center', height: '100%', fontSize: '18px'}}>
                <MdOutlineIosShare /> 
            </div>
           {showQRCode ? 'Hide' : 'Share Listing'}
          </button>
      </div>
      {showQRCode && (
        <div className={css.qrContainer}>
          <QRCode id="qrCode" value={url} size={256} />
          <br />
          <button
            onClick={handleDownload}
            className={`${css.web3Button} ${css.downloadButton}`}
          >
            Download QR Code
          </button>
        </div>
      )}
    </div>
  );
};

export default ShareQRCode;
