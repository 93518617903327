import React, { useEffect, useState } from 'react';
import ListingImageGallery from './ListingImageGallery/ListingImageGallery';

import css from './ListingPage.module.css';
import ShareQRCode from '../../components/QRCode/QRCode';
import { Button } from '../../components';

const SectionGallery = props => {
  const { listing, variantPrefix, client, getBoatForRent, getBoatForSale, listingType, boatUUID, getBoatSellPrice, setNftBoatListing, getBoatRentalTerms, rentalContract, isOwnListing, currentListingURL, nftBoatListing, hasWeb3, web3Handler } = props;
  const images = listing.images;
  const imageVariants = ['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge'];
  const thumbnailVariants = [variantPrefix, `${variantPrefix}-2x`, `${variantPrefix}-4x`];
  const [boatNft, setBoatNft] = useState(null);
  const [showNftImage, setShowNftImage] = useState(false);
  // console.log({boatUUID})
  // console.log({currentListingURL})


  const fetchBoatNft = async () => {
    if (boatUUID && client) {
      try {
        let boat;
        if (listingType === "sale") {
          boat = await getBoatForSale({ uuid: boatUUID });
          if (boat.success && parseFloat(boat.askingPrice) === 0) {
            console.log('Boat asking price is zero. Checking again.');
            const sellPrice = await getBoatSellPrice(boat.tokenId);
            console.log({sellPrice})
            if (!sellPrice || sellPrice === 0) {
              console.log('Boat asking price is zero after recheck. Setting boat to null.');
              setBoatNft(null);
              setNftBoatListing(null);
              return;
            } else {
              boat.askingPrice = sellPrice;
            }
          }
        } else {
          boat = await getBoatForRent({ uuid: boatUUID });
          if (boat.success && parseFloat(boat.rentalTerms.hourlyPrice) === 0) {
            console.log('Boat rental terms hourly price is zero. Checking again.');
            const rentalTerms = await getBoatRentalTerms(boat.tokenId);
            if (!rentalTerms || rentalTerms.hourlyPrice === 0) {
              console.log('Boat rental terms hourly price is zero after recheck. Setting boat to null.');
              setBoatNft(null);
              setNftBoatListing(null);
              return;
            } else {
              boat.rentalTerms = rentalTerms;
            }
          }
        }

        if (boat.success) {
          const response = await fetch(boat.tokenURI);
          const data = await response.json();
          const boatData = { ...boat, nftImage: data.nftImage };
          console.log('Boat has an NFT:', boatData);
          setBoatNft(boatData);
          setNftBoatListing(boatData);
          setShowNftImage(true);
        } else {
          console.log('Boat does not have an NFT');
          setBoatNft(null);
          setNftBoatListing(null);
        }
      } catch (error) {
        console.error("Error fetching boat NFT:", error);
        setBoatNft(null);
        setNftBoatListing(null);
      }
    } else {
      console.log('wallet not connected');
    }
  };

  useEffect(() => {
    fetchBoatNft();
  }, [client, boatUUID, listingType, rentalContract]);

  return (
    <section className={css.productGallery} data-testid="carousel">
      <div style={{display: 'flex', justifyContent: 'right', margin: '10px 0'}}>
        <ShareQRCode url={currentListingURL} />
      </div>
      {showNftImage && boatNft?.nftImage ? (
        <>
          <div>
            <img src={boatNft.nftImage} alt="NFT" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
            <button onClick={() => setShowNftImage(false)} style={{ padding: '10px 20px', margin: '0 10px', backgroundColor: '#007bff', color: '#fff', border: 'none', cursor: 'pointer', borderRadius: '5px' }}>
              Show Gallery
            </button>
          </div>
        </>
      ) : (
        <>
          <ListingImageGallery
            images={images}
            imageVariants={imageVariants}
            thumbnailVariants={thumbnailVariants}
          />
          {boatNft?.nftImage && (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
              <button onClick={() => setShowNftImage(true)} style={{ padding: '10px 20px', margin: '0 10px', backgroundColor: '#007bff', color: '#fff', border: 'none', cursor: 'pointer', borderRadius: '5px' }}>
                Show NFT Image
              </button>
            </div>
          )}
        </>
      )}
      {!hasWeb3 ? (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
          <a
            href="https://metamask.io/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ width: '60%', marginLeft: "10px" }}
          >
            Download MetaMask
          </a>
        </div>
      ) : client ? (
        nftBoatListing ? null : (
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
            <button onClick={fetchBoatNft} style={{ padding: '10px 20px', margin: '0 10px', backgroundColor: '#007bff', color: '#fff', border: 'none', cursor: 'pointer', borderRadius: '5px' }}>
              Check for NFT
            </button>
          </div>
        )
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
          <Button
            onClick={web3Handler}
            style={{ width: '60%', marginLeft: "10px" }}
          >
            Connect Wallet to Check for NFT
          </Button>
        </div>
      )}


    </section>
  );
};

export default SectionGallery;
